import { customCssClasses } from '@wix/editor-elements-common-utils';
import classNames from 'classnames';
import React, { memo, useRef } from 'react';
import semanticClassNames from '../../../StylableHorizontalMenu.semanticClassNames';
import { IMenuItemProps } from '../../../StylableHorizontalMenu.types';
import { testIds } from '../../testIds';
import { MenuItemLabel } from './MenuItemLabel';

export const MenuItem: React.FC<IMenuItemProps> = memo(props => {
  const {
    item,
    depth = 0,
    currentPageHref,
    className,
    submenuProps: { positionUpdaters, getClasses, injectCssVars },
    children,
    isColumnStretched,
    isContainerStretched,
    hasColumnSubSubs,
    onItemClick,
    onItemMouseIn,
    onItemMouseOut,
    onItemDblClick,
  } = props;

  const { label, items } = item;
  const positionBoxRef = useRef<HTMLDivElement | null>(null);
  const isStretched = children ? isContainerStretched : isColumnStretched;

  const submenuClasses = getClasses({
    depth,
    isStretched,
    containsChildren: !!children,
    hasColumnSubSubs,
  });
  // inject CSS vars from stylable panel
  const cssVars = injectCssVars?.(depth);

  return (
    <MenuItemLabel
      className={className}
      item={item}
      currentPageHref={currentPageHref}
      depth={depth}
      isStretched={isStretched}
      hasColumnSubSubs={hasColumnSubSubs}
      withSubItemsClassname={
        items?.length
          ? classNames(
              submenuClasses.hasSubItems,
              customCssClasses(semanticClassNames.subMenuTitle),
            )
          : ''
      }
      positionUpdaters={positionUpdaters}
      positionBoxRef={positionBoxRef}
      onItemClick={onItemClick}
      onItemDblClick={onItemDblClick}
      onItemMouseIn={onItemMouseIn}
      onItemMouseOut={onItemMouseOut}
    >
      {(items?.length || children) && (
        <div
          className={submenuClasses.positionBox}
          ref={positionBoxRef}
          role="region"
          aria-label={label}
          data-testid={testIds.positionBox}
        >
          <div
            className={classNames(
              submenuClasses.animationBox,
              customCssClasses(semanticClassNames.subMenuContainer),
            )}
            style={{
              ...cssVars?.animationBox,
              ...cssVars?.columnsLayout,
              ...(children && cssVars?.megaMenuContainer),
            }}
          >
            <div className={submenuClasses.alignBox}>
              {children || (
                <ul className={submenuClasses.list} style={cssVars?.list}>
                  {items!.map((subItem, i) => (
                    <MenuItem
                      {...props}
                      key={i}
                      item={subItem}
                      depth={depth + 1}
                      className={submenuClasses.subItem!}
                    />
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </MenuItemLabel>
  );
});
